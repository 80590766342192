import { useDark, useToggle } from '@vueuse/core'

/**
 * Manages the user's preferred color mode setting.
 */
export const useColorMode = () => {
  const isDark = useDark({
    selector: 'html',
    attribute: 'theme-mode',
    valueDark: 'dark',
    valueLight: 'light',
  })

  const toggle = useToggle(isDark)

  return { isDark, toggle }
}
